import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About Us',
        description = "A & D Associates is a promising digital distribution and a Gaming and Entertainment service provider that develops innovative digital platforms, products, and content to service and reach audiences via varied digital distribution streams. The company's unique position in the market as an eager innovator and unremitting trendsetter has established new standards for the interminable scope of Digital Entertainment - a segment that currently rules the market. Having established this distinct stronghold, A & D Associates has proved itself as an indisputable contributor in shaping the games and entertainment ecosystem. At the same time, the company has also come forward with new avenues for digital distribution, hence creating authentic revenue streams for media houses, app developers and content partners on and display the details.";
        return(
            <React.Fragment>
                <div className="about-wrapper" >
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/abot/vr.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2  style={{color:"#ab0707"}} className="title theme-gradient">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;