import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import About from "../component/HomeLayout/homeOne/About";
import Partners from "../elements/Partners";
import ContactUs from "../elements/ContactUs";

class MainDemo extends Component {
  render() {
    return (
      <div className="active-dark">
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
        />

        {/* Start Slider Area   */}
        <div className="slider-wrapper" id="home">
          <SliderOne />
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div
          id="about"
          className="about-area about-position-top pb--120 bg_color--1"
        >
          <About />
        </div>
        {/* End About Area */}

        {/* Start Service Area  */}
        <div
          id="service"
          className="service-area ptb--80  bg_image bg_image--3"
        >
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Brand Area */}
        <div id="partners" className="rn-brand-area brand-separation pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Partners />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <div
          id="contactUs"
          className="service-area ptb--80  bg_image bg_image--3"
        >
          <div className="container">
            <ContactUs />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default MainDemo;
