import React, { Component } from "react";
import { FiCast, FiLayers, FiMonitor } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiCast/>,
    title: "Consulting & Management",
  description:
  <ul type="circle"><li>Scoping and Planning</li> 
  <li>Governance</li>
  <li>Reviews and Audits</li>
  <li>Recommendations</li>
  <li>Processes and Design</li>
  </ul>
    },
    {
      icon: <FiMonitor/>,
      title: "Application Development & Support",
      description:
      <ul type="circle">
        <li>Discovery workshops to elicit requirements</li>
        <li>Solution Architecture & design</li>
        <li>Flexible delivery models</li>
        <li>Test and Learn methodology</li>
        <li>Continuous Monitoring</li>
      </ul>
      },
  {
    icon: <FiLayers/>,
    title: "Product and Infrastructure Management",
    description:
    <ul type="circle">
      <li>Product Consulting and Development</li>
      <li>Quality Check and Assurance</li>
      <li>Infrastructure Design & Auditing</li>
      <li>Infrastructure Auditing</li>
      <li>Hosting and maintenance of Services</li>
    </ul>
    },
 
];

class Itsolution extends Component {
  render() {
    let title = "IT Solutions",
      description =
        "A and D Associates has evolved itself from the Digital domain to an IT solution provider as well, With the help of our inhouse or outsourced IT teams, A and D Associates can handle all sort of development requirements. We offer value added services for technologies and applications relating to our products as well. ";
    return (
        <div className="active-dark" id="itsolution" > 
        <div style={{paddingTop:"50px", paddingBottom:"20px"}}>
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10" >
              <h2 style={{ color: "#ab0707", fontSize:"2.5rem" }} className="title theme-gradient">
                {title}
              </h2>
              <p>{description}</p>
            </div>
          </div>
          
          <div className="col-lg-8 col-12 mt_md--50" style={{marginTop:"25px"}} >
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i} >
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3  className="title" >
                        {val.title}
                      </h3>
                      <p style={{whiteSpace:'pre-wrap'}}>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          </div>
        
      </React.Fragment>
      </div>
      
      </div>
    );
  }
}
export default Itsolution;
