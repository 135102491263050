import React, { Component } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import { HashLink } from "react-router-hash-link";
import '../../css/header.css';


class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    // const { logo, color='default-color' } = this.props;
    // let logoUrl;
    // if(logo === 'light'){
    //     logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
    // }else if(logo === 'dark'){
    //     logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
    // }else if(logo === 'symbol-dark'){
    //     logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
    // }else if(logo === 'symbol-light'){
    //     logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
    // }else{
    //     logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
    // }

    return (
      <header
        className={`header-area formobile-menu header--transparent  `}
        style={{
          position: "fixed",
          top: "0",
          zIndex: "100",
          backgroundColor: "black",
        }}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo" style={{postion:'relative'}}>
               <a href="/">
               <img height="20%" width="25%" src="/assets/images/mainIcon/AnD.png" alt="logo"/>
        </a>
        <h3
                className="theme-gradient header-title"
              >
                <span class="pre-text" style={{fontSize:"35px"}}>A</span>
             ssociates
              </h3>
            </div>
           
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <HashLink smooth to="/#home">
                    Home
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/#about">
                    About
                  </HashLink>
                </li>
                <li className="has-droupdown">
                  <HashLink smooth to="/#service">
                    Service
                  </HashLink>
                  <ul className="submenu">
                    <li>
                      <HashLink smooth scroll={(el)=>{el.scrollIntoView({behavior:"smooth", block:"start"})}} to="/vas/#vas">VAS</HashLink>
                    </li>
                    <li>
                      <HashLink smooth scroll={(el)=>{el.scrollIntoView({behavior:"smooth", block:"start"})}}  to="/vas/#itsolution">IT solution</HashLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <HashLink smooth to="/#partners">
                    Partners
                  </HashLink>
                </li>

                <li>
                  <HashLink smooth to="/#contactUs">
                    Contact
                  </HashLink>
                </li>
              </ul>
            </nav>

            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
