import React, { Component } from "react";

class Partners extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="col-lg-4 col-12">
          <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
            <h2 style={{color:"#ab0707"}} className="theme-gradient">Our Partners</h2>
          </div>
        </div>

        <ul
          className="brand-style-2"
        >
          <div className="col-lg-6 col-md-12">
            <div style={{textAlign:"center"}}><h3 style={{color:"#ab0707", fontSize:"2rem"}} className="theme-gradient">Telecom Partner</h3></div>
            <li>
              
              <img
                style={{ filter: "invert(0)"}}
                src="/assets/images/partners/ncell.png"
                alt="Logo Images"
              />
            </li>
          </div>
          <div className="col-lg-6 col-md-12">
            <div style={{textAlign:"center"}}><h3 style={{color:"#ab0707", fontSize:"2rem"}} className="theme-gradient">Banking Partner</h3> </div>
            <li>
              
              <img
                style={{ filter: "invert(0)" }}
                src="/assets/images/partners/nbl.png"
                alt="Logo Images"
              />
            </li>
          </div>

          <div className="col-lg-12 col-md-12" style={{textAlign:"center"}}>
          <h3 style={{color:"#ab0707", fontSize:"2rem"}} className="theme-gradient">E-wallet Partner</h3>

          </div>
          <div className="col-lg-12 col-md-12">

          
            <li>
              
              <img
                style={{ filter: "invert(0)" }}
                src="/assets/images/partners/Thaili.png"
                alt="Logo Images"
              />
            </li>
          </div>
          <div className="col-lg-12 col-md-12" style={{textAlign:"center"}}>
          <h3 style={{color:"#ab0707", fontSize:"2rem"}} className="theme-gradient">Service Partners</h3>

          </div>
          <div className="col-lg-4 col-md-12">
            <li>
              
              
              <img
                style={{ filter: "invert(0)" }}
                src="/assets/images/partners/nazaro.png"
                alt="Logo Images"
              />
              
            </li>
          </div>
         
          <div className="col-lg-4 col-md-12">
            <li>
              
              <img
                style={{ filter: "invert(0)" }}
                src="/assets/images/partners/shemaroo.png"
                alt="Logo Images"
              />
            </li>
          </div>
          <div className="col-lg-4 col-md-12">
            <li>
              
              
              <img
                style={{ filter: "invert(0)" }}
                src="/assets/images/partners/redbee.jpg"
                alt="Logo Images"
              />
              
            </li>
          </div>
        </ul>
      </React.Fragment>
    );
  }
}
export default Partners;
