import React, { Component } from "react";
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";

class ContactUs extends Component {
  render() {
    return (
      <React.Fragment>
             
        <div className="col-lg-6 col-12" style={{paddingTop:"30px"}}>
          <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10" style={{paddingTop:"10px"}}>
            <h2 style={{color:"#ab0707"}} className="theme-gradient">Contact Us</h2>
          </div>
        </div>
        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiPhone />
                  </div>
                  <div className="inner">
                    <h4 style={{ color: "#ab0707" }} className="theme-gradient">Mobile Number</h4>
                    <p>
                      <a href="tel:9801102323">9801102323</a>
                    </p>
                   
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 style={{ color: "#ab0707" }} className="theme-gradient">Email Address</h4>
                    <p>
                      <a href="mailto:info@andsociates.com">info@andsociates.com</a>
                    </p>
                   
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 style={{ color: "#ab0707" }} className="theme-gradient">Location</h4>
                    <p>
                     Pulchowk, Lalitpur 44700
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}
      </React.Fragment>
    );
  }
}

export default ContactUs;


// mt_mobile--50