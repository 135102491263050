import React, { Component } from "react";
import Header from "../component/header/Header";
import { GiRadioTower } from "react-icons/gi";
import Itsolution from "./ITsolution";

const ServiceList = [
  {
    icon: <GiRadioTower />,
    title: "Telecom VAS",
    description: (
      <ul type="circle">
        <li>WAP Portal</li>
        <li>Games Portal</li>
        <li>IVR Services</li>
        <li>USSD Services</li>
      </ul>
    ),
  },
];

class VAS extends Component {
  render() {
    let title = "VAS (Value Added Service)",
      description =
        "A and D Associates has been ubiquitously working on the Value added services for telecom Operators ad Devices. Our VAS services include IVR, USSD, SMS Web, Wap or Apps.";
    return (
      <div className="active-dark" style={{ minHeight: "100vh" }} id="vas">
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
        />
        <div
          style={{
            paddingTop: "80px",
            paddingBottom: "20px",
            marginLeft: "4%",
            marginRight: "3%",
          }}
        >
          <React.Fragment>
            <div className="row">
              <div
                className="col-lg-4 col-12"
              >
                <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                  <h2
                    style={{ color: "#ab0707", fontSize: "2.5rem" }}
                    className="title theme-gradient"
                  >
                    {title}
                  </h2>
                  <p>{description}</p>
                </div>
              </div>
            

            
              <div
                className="col-lg-8 col-12 mt_md--50"
                style={{
                  marginTop: "25px",
                }}
              >
                <div className="row service-one-wrapper">
                  {ServiceList.map((val, i) => (
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                      <div className="service service__style--2">
                        <div className="icon">{val.icon}</div>
                        <div className="content">
                          <h3 className="title ">{val.title}</h3>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {val.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            
            </div>
            <Itsolution/>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
export default VAS;
