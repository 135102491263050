// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

// Dark Home Layout
import DarkMainDemo from "./dark/MainDemo";
import VAS from "./additionalServices/VAS";
import ITsolution from "./additionalServices/ITsolution";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={DarkMainDemo}
          />
          <Route exact path={`${process.env.PUBLIC_URL}/vas`} component={VAS} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/it`}
            component={ITsolution}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
