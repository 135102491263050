import React, { Component } from "react";
import { FiCast, FiLayers, FiMonitor } from "react-icons/fi";
import {FaHandshake } from "react-icons/fa";

const ServiceList = [
  {
    icon: <FiCast />,
    title: "Service Operations",
    description:
      "We simplify the transaction process by providing a one-time, direct technical integration. Our experienced team directly manages the technical development, integration, testing, validation and deployment with all our DCB partners.",
  },
  {
    icon: <FiLayers />,
    title: "Content Management",
    description:
      "Onboard and manage content providers from price negotiations to contractual obligations, content implementation and reporting activities. Our services include acquisition of programs and channels from all the major content suppliers.",
  },
  {
    icon: <FiMonitor />,
    title: "Digital Content Processing",
    description:
      "Whether this is for video on demad, live linear broadcast or archiving, A & S Associates has the technical solution, experience, creativity and team to acquire content and process it based on our clients' needs.",
  },
  {
    icon: <FaHandshake />,
    title: "Partners Management",
    description:
      "We are uniquely positioned to develop key strategic partnerships for content owners and service providers, giving them unparalleled marketing opportunities through a variety of monetization models to reach their target audiences.",
  },
  {
    icon: <FaHandshake />,
    title: "Local VAS Vendors",
    description:
      "A and D Associates is actively bound to new technologies and services for the VAS market. As a local VAS vendor we maintain strong operator relationships, existing infrastructure, and understanding of the local mobile ecosystem in a smart way for development actors to hedge risk, while also creating a pathway for commercial sustainability.",
  },
];

class ServiceTwo extends Component {
  render() {
    let title = "Services",
      description =
        "A & D Associates specializes in providing end-to-end solutions, from platform ownership to billing and services that bring together content, technology and device expertise.";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 style={{ color: "#ab0707" }} className="title theme-gradient">
                {title}
              </h2>
              <p>{description}</p>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50" >
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => {
              
                return(
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3  className="title ">
                        {val.title}
                      </h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              )})}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
