import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Ready To Do This</span>
                    <h2>
                      Let's get <br /> to work
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4
                          style={{ color: "#ab0707" }}
                          className="theme-gradient"
                        >
                          Quick Link
                        </h4>
                        <ul className="ft-link">
                          <li>
                            <HashLink smooth to="/#home">Home</HashLink>
                          </li>
                          <li>
                            <HashLink smooth to="/#about">
                              About
                            </HashLink>
                          </li>
                          <li>
                            <HashLink smooth to="/#service">
                              Service
                            </HashLink>
                          </li>
                          <li>
                            <HashLink smooth to="/#partners">
                              Partners
                            </HashLink>
                          </li>

                          <li>
                            <HashLink smooth to="/#contactUs">
                              Contact
                            </HashLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4
                          style={{ color: "#ab0707" }}
                          className="theme-gradient"
                        >
                          Connect with Us
                        </h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:info@andsociates.com">
                              info@andsociates.com
                            </a>
                          </li>
                          <li>
                            <a href="">Pulchowk, Lalitpur 44700</a>
                          </li>
                          <li>
                            {" "}
                            <a href="tel:9801102323">9801102323</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Copyright © 2021 A & D Associates-. All Rights
                          Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
